import { render, staticRenderFns } from "./UserInfo.vue?vue&type=template&id=3b58e929&scoped=true&"
import script from "./UserInfo.vue?vue&type=script&lang=ts&"
export * from "./UserInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b58e929",
  null
  
)

export default component.exports